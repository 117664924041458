import * as queryString from 'query-string';
import axios from 'axios';
import Cookies from 'js-cookie';

const parameters = queryString.parse(window.location.search);
export const baseUrl =
  parameters.api_url || process.env.REACT_APP_API_URL || 'https://api-pro.aimmsystem.com';
export const apiUrl = baseUrl + '/api/v1/';

const isLoginPath = path => path === '/token';

const JSON_HEADERS = {
  'AIMM-TZ': 'Pacific Standard Time',
  'Content-Type': 'application/json',
};

function attachAuthorizationHeader(requestConfig) {
  const token = Cookies.get('token');

  return token
    ? Object.assign({}, requestConfig, {
        headers: Object.assign({}, requestConfig.headers || {}, {
          Authorization: `Bearer ${token}`,
        }),
      })
    : requestConfig;
}

function errorHandlerDecorator(requestCoreFunc) {
  return async function errorHander(path, config) {
    const response = await requestCoreFunc(path, config);

    return response.status === 204 // NOTE: No content
      ? null
      : response;
    // NOTE: Handle Error response
  };
}

async function requestCore(path, config) {
  return axios({
    url: `${isLoginPath(path) ? baseUrl : apiUrl}${path}`,
    ...attachAuthorizationHeader(config),
  });
}

const request = errorHandlerDecorator(requestCore);

export async function post(path, content) {
  const response = await request(path, {
    method: 'post',
    headers: JSON_HEADERS,
    data: isLoginPath(path) ? queryString.stringify(content) : content,
  });

  return (
    response && {
      status: response.status,
      headers: response.headers,
      data: response.data,
    }
  );
}

export async function get(path, queryParams = {}) {
  const qry = queryString.stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await request(`${path}${qry ? '?' : ''}${qry}`, {
    method: 'get',
  });

  return (
    response && {
      status: response.status,
      headers: response.headers,
      data: response.data,
    }
  );
}

export async function put(path, content) {
  const response = await request(path, {
    method: 'put',
    headers: JSON_HEADERS,
    data: JSON.stringify(content),
  });

  return (
    response && {
      status: response.status,
      headers: response.headers,
      data: response.data,
    }
  );
}

export async function patch(path, content) {
  const response = await request(path, {
    method: 'patch',
    headers: JSON_HEADERS,
    data: JSON.stringify(content),
  });

  return (
    response && {
      status: response.status,
      headers: response.headers,
      data: response.data,
    }
  );
}

export async function del(path) {
  const response = await request(path, {
    method: 'delete',
    headers: JSON_HEADERS,
  });

  return (
    response && {
      status: response.status,
      headers: response.headers,
      data: response.data,
    }
  );
}
